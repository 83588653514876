//Migrate to Stimulus Controller

//https://medium.com/@sunil.jadhav38/implementing-marker-clustering-is-angular-using-google-charts-6b62a33f3b61
//https://github.com/googlemaps/v3-utility-library/blob/master/packages/markerclustererplus/examples/events_example.html
//https://stackoverflow.com/questions/25267146/google-maps-javascript-api-v3-data-layer-markerclusterer

//TODO: info window grouped together at same location

import { FetchRequest, get, post, put, patch, destroy } from '@rails/request.js'
import { MarkerClusterer } from "@googlemaps/markerclusterer";

var map;
var markers = [];
var includedShapeDataLayer;
var excludedShapeDataLayer;
var markerDataLayer;

function callbackInitMap() {
  //Needed for loading GoogleMapJS file
  // but maps are loaded through a stimulus controller
}

export function initializeMap() {
  var infowindow = new google.maps.InfoWindow();
  var bounds = new google.maps.LatLngBounds();

  if ($('#dashboard-map').length > 0) {
    map = initMap("dashboard-map");

    markerDataLayer = new google.maps.Data({map: map});
    includedShapeDataLayer = new google.maps.Data({map: map});
    excludedShapeDataLayer = new google.maps.Data({map: map});

    markerDataLayer.addListener('addfeature', function (e) {
      if (e.feature.getGeometry().getType() === 'Point') {
        var marker = new google.maps.Marker({
          position: e.feature.getGeometry().get(),
          title: e.feature.getProperty('name'),
          map: map
        });

        // open the infoBox when the marker is clicked
        marker.addListener('click', function (marker, e) {
          return function () {
            var venueId = e.feature.getProperty('venue_id');
            var searchId = e.feature.getProperty('search_id');

            const request = new FetchRequest("get", `/inventory/searches/${searchId}/venue_content_units/${venueId}`,  {responseKind: "turbo-stream"})
            const response = request.perform()
          };
        }(marker, e));

        markerClusterer.addMarker(marker);
        processPoints(e.feature.getGeometry(), bounds.extend, bounds);
      }
    });

    includedShapeDataLayer.addListener('addfeature', function (e) {
      processPoints(e.feature.getGeometry(), bounds.extend, bounds);
      map.fitBounds(bounds);
    });

    markerDataLayer.setStyle({ visible: false });
    markerDataLayer.loadGeoJson($("#dashboard-map").data("markersUrl"));

    includedShapeDataLayer.setStyle({ fillColor: 'blue', strokeWeight: 1 });
    var shapes = includedShapeDataLayer.loadGeoJson($("#dashboard-map").data("includedShapesUrl"));

    excludedShapeDataLayer.setStyle({ fillColor: 'red', strokeWeight: 1 });
    var excludedShapes = excludedShapeDataLayer.loadGeoJson($("#dashboard-map").data("excludedShapesUrl"));

    includedShapeDataLayer.addListener('click', function(event) {
      var feat = event.feature;
      var html = "<b>" + feat.getProperty('type') + ': ' + feat.getProperty('name') + "</b>";
      infowindow.setContent(html);
      infowindow.setPosition(event.latLng);
      infowindow.setOptions({pixelOffset: new google.maps.Size(0,-34)});
      infowindow.open(map);
    });

    excludedShapeDataLayer.addListener('click', function(event) {
      var feat = event.feature;
      var html = "<b>" + feat.getProperty('type') + ': ' + feat.getProperty('name') + "</b>";
      infowindow.setContent(html);
      infowindow.setPosition(event.latLng);
      infowindow.setOptions({pixelOffset: new google.maps.Size(0,-34)});
      infowindow.open(map);
    });

    var markerClusterer = new MarkerClusterer({ map, markers });
    markerClusterer.setMap(map);
  }
}

export function initMap(id) {
  return new google.maps.Map(document.getElementById(id), {
    center: {lat: 37.09024, lng: -95.712891},
    zoom: 4
  });
}

function processPoints(geometry, callback, thisArg) {
  if (geometry instanceof google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach(function(g) {
      processPoints(g, callback, thisArg);
    });
  }
}


window.callbackInitMap = callbackInitMap;
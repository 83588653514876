// select_list_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["count", "selected", "source", "checked"];
  static values = { header: String }

  connect() {
    this.setCount();
  }

  onChange(event) {
    this.setCount();
  }

  clearAll(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.selectInputs.forEach((el) => {
      el.value = ''
    });
    this.setCount();
  }

  setCount() {
    const count = this.selectedSelectInputs.length;
    this.displayCount(count);
  }

  displayCount(count) {
    if (this.hasSourceTarget) {
      if (count > 0) {
        this.sourceTarget.placeholder = `${count} ${this.headerValue}(s) selected`;
      }
      else {
        this.sourceTarget.placeholder = `Select ${this.headerValue}(s)`;
      }
    }
  }

  get selectedSelectInputs() {
    return this.selectInputs.filter((si) => si.value != '');
  }

  get unselectedSelectInputs() {
    return this.selectInputs.filter((c) => c.value == '');
  }

  get selectInputs() {
    return new Array(...this.element.querySelectorAll("select"));
  }
}
// select_toggle_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "source", "content" ];
  static classes = [ "toggle" ];
  static values = {
    toggle: String
  }

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.sourceTarget.value == this.toggleValue) {
      this.contentTarget.classList.add(this.toggleClass);
    }
    else {
      this.contentTarget.classList.remove(this.toggleClass);
    }
  }

}
import { Controller } from "stimulus"
import * as bootstrap from 'bootstrap'
import { FetchRequest, get, post, put, patch, destroy } from '@rails/request.js'

export default class extends Controller {
  static targets = [ "searchField", "dropdown", "dropdownMenu", "dropdownList", "selectedItem" ]
  static classes = [ "disabled" ];
  static values = {
    requestWaitTime: Number,
    recordCount: Number,
    recordsUrl: String
  }

  connect() {
    this.timeout = null;
  }

  focus(event) {
    this.show();
    this.search();
  }

  blur(event) {
    this.hide();
  }

  input(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(function() {
      this.search()
    }.bind(this), this.requestWaitTimeValue);
  }

  select(event) {
    event.preventDefault();
    event.currentTarget.classList.add(this.disabledClass);
    this.sendGet(event.currentTarget.dataset.url);
  }

  remove(event) {
    event.preventDefault();
    document.getElementById(event.currentTarget.dataset.targetId).remove();
  }

  show() {
    $(this.dropdownListTarget).addClass("show");
    $(this.dropdownTarget).addClass("show");
  }

  hide() {
    if (event.target == this.searchFieldTarget) {
      return;
    }
    if (event.target.classList.contains("dropdown-item")) {
      return;
    }
    $(this.dropdownListTarget).removeClass("show");
    $(this.dropdownTarget).removeClass("show");
  }

  search() {
    var formData = new FormData();

    formData.append("query", this.searchFieldTarget.value);
    formData.append("records", this.recordCountValue);

    this.selectedItemTargets.forEach((el)=> {
      formData.append("selected_item_ids[]", el.value);
    });

    this.sendPost(this.recordsUrlValue, formData);
  }

  async sendGet(url) {
    const response = await get(url, {
      responseKind: "turbo-stream"
    });
    if (response.ok) {
    }
  }

  async sendPost(url, body) {
    const response = await post(url, {
      body: body,
      responseKind: "turbo-stream"
    });
    if (response.ok) {
    }
  }

}

//Migrate to Stimulus Controller ??

window.initRangePicker = initRangePicker;

function initRangePicker(elements) {
  $.each(elements, function(index, item) {
    var element = $(item);
    if (element.hasClass("time-picker")) {
      initTimePicker( element )
    }
    else if (element.hasClass("date-time-picker")) {
      initDateTimePicker( element )
    }
    else if (element.hasClass("date-picker")) {
      initDatePicker( element )
    }
    else if (element.hasClass("date-time-range-picker")) {
      initDateTimeRangePicker( element, element.data("startAt"), element.data("endAt") )
    }
    else {
      initDateRangePicker( element, element.data("startAt"), element.data("endAt") )
    }
  })
}
function getRanges(type) {
  if (type == "none" ) {
    return {}
  }
  if (type == "past" ) {
    return {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Previous 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
      'Previous 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
      'Previous Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  }
  return {
    'Today': [moment(), moment()],
    'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')],
    'Next 7 Days': [moment().add(1, 'days'), moment().add(7, 'days')],
    'Next 30 Days': [moment().add(1, 'days'), moment().add(30, 'days')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
  }

}
function initDateRangePicker(picker, startElement, endElement) {
  /* Single Date Range Picker */
  var start = $(startElement);
  var end = $(endElement);
  var ranges = getRanges($(picker).data("rangeType"));
  var hasRanges = (Object.keys(ranges).length > 0);
  var minDate = $(picker).data("minDate");
  var maxDate = $(picker).data("maxDate");

  options = {
    startDate: moment(start.val()).startOf('day'),
    endDate: moment(end.val()).endOf('day'),
    alwaysShowCalendars: true,
    showCustomRangeLabel: hasRanges,
    minDate: minDate,
    maxDate: maxDate,
    locale: {
      format: 'MM/DD/YYYY'
    }
  }
  if (hasRanges) {
    options["ranges"] = ranges
  }

  picker.daterangepicker(options);

  picker.on('apply.daterangepicker', function(ev, picker) {
    start.val(picker.startDate.format('YYYY-MM-DD'));
    end.val(picker.endDate.format('YYYY-MM-DD'));
    $(this).find(".daterange-label").html(picker.startDate.format('MMM D, YYYY') + ' - ' + picker.endDate.format('MMM D, YYYY'));
  });
}

function initDateTimeRangePicker(picker, start, end) {
  var startDate;
  var endDate;
  /* Single DateTime Range Picker */
  startDate = moment( $(start).val() ).utc();;
  endDate = moment( $(end).val() ).utc();;
  if ( !startDate.isValid() ) {
    startDate = moment().startOf('day');
  }
  if ( !endDate.isValid() ) {
    endDate = moment().endOf('day');
  }
  picker.daterangepicker({
    timePicker: true,
    startDate: startDate,
    endDate: endDate,
    alwaysShowCalendars: true,
    ranges: {
      'Today': [moment(), moment()],
      'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')],
      'Next 7 Days': [moment().add(1, 'days'), moment().add(7, 'days')],
      'Next 30 Days': [moment().add(1, 'days'), moment().add(30, 'days')],
      'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')]
    },
    locale: {
      format: 'MM/DD/YYYY hh:mm A'
    }
  });

  picker.on('apply.daterangepicker', function(ev, pick) {
    $(start).val(pick.startDate.format('YYYY-MM-DD hh:mm A'));
    $(end).val(pick.endDate.format('YYYY-MM-DD hh:mm A'));
  });
}

function initDatePicker(picker) {
  /* Single Date Picker */
  picker.daterangepicker({
    singleDatePicker: true,
    showDropdowns: true,
    minDate: moment().startOf('day'),
    startDate: moment().startOf('day'),
    autoUpdateInput: false,
    locale: {
      format: 'YYYY/MM/DD hh:mm A'
    }
  });
  picker.on('apply.daterangepicker', function(ev, pick) {
    $(this).val(pick.startDate.format('YYYY/MM/DD'));
  });

}

function initDateTimePicker(picker) {
  /* Single DateTime Picker */
  picker.daterangepicker({
    singleDatePicker: true,
    showDropdowns: true,
    minDate: moment().startOf('day'),
    autoUpdateInput: false,
    timePicker: true,
    startDate: moment().startOf('day'),
    locale: {
      format: 'YYYY/MM/DD hh:mm A'
    }
  });
  picker.on('apply.daterangepicker', function(ev, pick) {
    $(this).val(pick.startDate.format('YYYY/MM/DD hh:mm A'));
  });

}

function initTimePicker(element){
  //timePicker24Hour: true,
  element.daterangepicker({
    singleDatePicker: true,
    timePicker: true,
    timePickerSeconds: true,
    timePickerIncrement: 1,
    locale: {
        format: 'hh:mm:ss A'
    }
  }).on('show.daterangepicker', function (ev, picker) {
      picker.container.find(".calendar-table").hide();
  });
}

document.addEventListener("turbo:load", () => {
  initRangePicker($("div.range-picker"));
})

$(document).on("click", ".icon-date", function(event) {
  event.preventDefault();
  var target = $(this).data("target")
  $(target).trigger('show.daterangepicker');
});

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nested_fields_wrapper", "templates"]
  static classes = [ "fieldsWrapper" ];

  connect() {
    this.removedElements = [];
  }

  add_association(event) {
    event.preventDefault();
    let template = this.templatesTarget.querySelector("template");
    let content = template.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf());
    this.nested_fields_wrapperTarget.insertAdjacentHTML('beforeend', content);
  }

  remove_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest(`.${this.fieldsWrapperClass}`);
    console.log(wrapper)
    wrapper.querySelector(".nested-fields-inputs").disabled = true;
    wrapper.querySelector(".nested-fields-inputs").classList.add("d-none");
    wrapper.querySelector(".nested-fields-removed").classList.remove("d-none");
  }

  unremove_association(event) {
    event.preventDefault()
    let wrapper = event.target.closest(`.${this.fieldsWrapperClass}`);
    wrapper.querySelector(".nested-fields-inputs").disabled = false;
    wrapper.querySelector(".nested-fields-inputs").classList.remove("d-none");
    wrapper.querySelector(".nested-fields-removed").classList.add("d-none");
  }

  mark_for_destruction(event) {
    event.preventDefault()
    let wrapper = event.target.closest(`.${this.fieldsWrapperClass}`);
    wrapper.querySelector("input[name*='_destroy']").value = 1
    wrapper.querySelector(".nested-fields-inputs").classList.add("d-none");
    wrapper.querySelector(".nested-fields-removed").classList.remove("d-none");
  }

  unmark_for_destruction(event) {
    event.preventDefault()
    let wrapper = event.target.closest(`.${this.fieldsWrapperClass}`);
    wrapper.querySelector("input[name*='_destroy']").value = 0
    wrapper.querySelector(".nested-fields-inputs").classList.remove("d-none");
    wrapper.querySelector(".nested-fields-removed").classList.add("d-none");
  }

}
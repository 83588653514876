// toggle_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "content" ];
  static classes = [ "hidden" ];

  toggle(event) {
    this.contentTarget.classList.toggle(this.hiddenClass);
  }

  show(event) {
    this.contentTarget.classList.remove(this.hiddenClass);
  }

  hide(event) {
    //event.stopImmediatePropagation();
    if (event && (this.contentTarget.parentElement.contains(event.target))) {
      if (!event.target.getAttribute("data-toggle-exit")) {
        return;
      }
      else {
        event.preventDefault();
      }
    }
    this.contentTarget.classList.add(this.hiddenClass);
  }
}
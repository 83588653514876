import { Controller } from "stimulus"

import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
    this.myModal = new bootstrap.Modal(this.modalTarget, {})
    this.myModal.show();
  }

  hideModal() {
    this.myModal.hide();
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

}

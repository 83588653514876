// checkbox_list_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["count", "selected", "source", "checked"];
  static values = { header: String }


  connect() {
    this.setCount();
  }

  checkAll(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.setAllCheckboxes(true);
    this.setCount();
    this.showChecked()
  }

  checkNone(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.setAllCheckboxes(false);
    this.setCount();
    this.showChecked()
  }

  onChecked(event) {
    this.setCount();
    //this.setSelected(event.target);
  }

  onCheckboxAdd(event) {
    this.incrementCount()
  }

  onCheckboxRemove(event) {
    this.decrementCount()
  }

  showChecked(event) {
    this.unselectedCheckboxes.forEach((el, i) => {
      el.parentElement.classList.toggle("d-none", this.checkedTarget.checked )
    })
  }

  setAllCheckboxes(checked) {
    this.checkboxes.forEach((el) => {
      const checkbox = el;

      if (!checkbox.disabled) {
        checkbox.checked = checked;
      }
    });
  }

  setCount() {
    const count = this.selectedCheckboxes.length;
    this.displayCount(count);
  }

  incrementCount() {
    const count = this.selectedCheckboxes.length + 1;
    this.displayCount(count);
  }

  decrementCount() {
    const count = this.selectedCheckboxes.length - 1;
    this.displayCount(count);
  }

  displayCount(count) {
    if (this.hasSourceTarget) {
      if (count > 0) {
        this.sourceTarget.placeholder = `${count} ${this.headerValue}(s) selected`;
      }
      else {
        this.sourceTarget.placeholder = `Select ${this.headerValue}(s)`;
      }
    }
  }

  get selectedCheckboxes() {
    return this.checkboxes.filter((c) => c.checked);
  }

  get unselectedCheckboxes() {
    return this.checkboxes.filter((c) => !c.checked);
  }

  get checkboxes() {
    return new Array(...this.element.querySelectorAll("input[type=checkbox]"));
  }
}
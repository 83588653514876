// checkbox_table_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["table", "source"];
  static classes = [ "checked" ]

  connect() {
    this.isToggling=false;
    this.checkVal=null;
    this.setCount();
  }

  toggle(element, checkVal) {
    var chk = element.querySelector("input[type=checkbox]")
    chk.checked = (checkVal==null) ? !chk.checked : checkVal;
    if (chk.checked) {
      element.classList.add(this.checkedClass)
    }
    else {
      element.classList.remove(this.checkedClass)
    }
    return chk.checked
  }

  onColumnHeaderClick(event) {
    var items = new Array(...this.tableTarget.querySelectorAll(`td[data-hour="${event.target.dataset.hour}"]`));
    var checkVal = (this.tableTarget.querySelectorAll(`td[data-hour="${event.target.dataset.hour}"] input[type=checkbox]:not(:checked)`).length > 0);
    items.forEach((el) => {
      this.toggle(el, checkVal)
    });
    this.setCount();
  }

  onRowHeaderClick(event) {
    var items = new Array(...this.tableTarget.querySelectorAll(`td[data-wday="${event.target.dataset.wday}"]`));
    var checkVal = false;

    var checkVal = (this.tableTarget.querySelectorAll(`td[data-wday="${event.target.dataset.wday}"] input[type=checkbox]:not(:checked)`).length > 0);

    items.forEach((el) => {
      this.toggle(el, checkVal)
    });
    this.setCount();
  }

  onMouseDown(event) {
    if (event.target.tagName != "TD") {
      return
    }
    this.isToggling=true;
    this.checkVal = this.toggle(event.target, null);
    this.setCount();
  }

  onMouseUp(event) {
    this.isToggling=false;
    this.checkVal=null;
  }

  onMouseEnter(event) {
    if (this.isToggling) {
      this.toggle(event.target, this.checkVal);
      this.setCount();
    }
  }

  setCount() {
    const count = this.selectedCheckboxes.length;
    if (this.hasSourceTarget) {
      this.sourceTarget.innerHTML = `(${count})`;
    }
  }

  get selectedCheckboxes() {
    return this.checkboxes.filter((c) => c.checked);
  }

  get checkboxes() {
    return new Array(...this.element.querySelectorAll("input[type=checkbox]"));
  }
}
// heatmap_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cell"];
  static values = { cssClassPrefix: String }

  connect() {
    this.format();
  }

  format() {
    let min = this.minCellValue;
    let max = this.maxCellValue;
    let cssClassValue = 5;
    let sectionRange = (max - min) / this.ranges;

    this.cellTargets.forEach((el, i) => {
      let cellValue = parseFloat(el.dataset.heatmapCellValue);

      if (isNaN(cellValue)) {
        return;
      }

      if (sectionRange > 0.0) {
        cssClassValue = Math.floor((cellValue - min) / sectionRange);
      }

      el.classList.add(`${this.cssClassPrefixValue}${cssClassValue}`);
    })
  }

  get ranges() {
    return 5.0
  }

  get minCellValue() {
    return Math.min(...this.cellValues);
  }

  get maxCellValue() {
    return Math.max(...this.cellValues);
  }

  get cellValues() {
    return new Array(...this.cellTargets.map((el) => parseFloat(el.dataset.heatmapCellValue)).filter((cellValue) => !isNaN(cellValue)))
  }

}
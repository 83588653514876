// ancestry_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["filterable"];
  static values = { padding: Number }

  connect() {
    this.format();
  }

  format() {
    this.filterableTargets.forEach((el, i) => {
      let style = window.getComputedStyle ? getComputedStyle(el.parentElement, null) : el.parentElement.currentStyle;
      let parentPaddingLeft = parseInt(style.paddingLeft) || 0
      let depth =  parseInt(el.getAttribute("data-ancestry-depth")) || 0
      let newPaddingLeft = parentPaddingLeft + (depth * this.paddingValue)
      el.parentElement.style.paddingLeft = newPaddingLeft + "px";
    })
  }

}
// filter_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "filterable"];
  static classes = [ "hidden" ];

  filter(event) {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase();

    this.filterableTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-filter-key").toLowerCase();
      el.parentElement.classList.toggle(this.hiddenClass, !filterableKey.includes( lowerCaseFilterTerm ) );
    })
  }

  reset(event) {
    event.preventDefault();
    this.sourceTarget.value = "";
    this.filter(event);
  }

}
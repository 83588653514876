// pagy_controller.js
import { Controller } from "stimulus";

import { Pagy } from '../packs/pagy.js';

export default class extends Controller {

  connect() {
    Pagy.init();
  }
}
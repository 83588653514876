// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import "@hotwired/turbo-rails"
import { Turbo, cable } from "@hotwired/turbo-rails"
import "controllers"
window.Turbo = Turbo;


//global.Rails = require('@rails/ujs');
//Rails.start();
//require("@rails/activestorage").start()
//require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//App's stylesheets
require('../stylesheets/application')

import $ from 'jquery';
global.$ = jQuery;

//FontAwesome
import "@fortawesome/fontawesome-free/js/all";

//Bootstrap
require('bootstrap')

//Moment - javascript Time
import moment from 'moment'
window.moment = moment

//DateRangePicker
require('bootstrap-daterangepicker')
require('./daterangepicker.js')

//Maps
require('./maps.js')

$(document).on("click", "form .dropdown label", function(event) {
  event.stopPropagation()
});

/*Fix issues with Popovers */
document.addEventListener("turbo:load", () => {
  $('[data-toggle="popover"]').popover({
    trigger: 'focus'
  })
});
// remove_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "content" ];
  static classes = [ "hidden" ];

  connect() {
    this.contentTarget.classList.remove(this.hiddenClass);
  }
  clear(event) {
    event.preventDefault();
    this.contentTarget.innerHTML = "";
    this.contentTarget.classList.add(this.hiddenClass);
  }
}